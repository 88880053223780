<template>
  <div>
    <b-overlay :show="loading">
      <b-button variant="outline-obitless" class="log-cell" @click="show(true)">
        <icon :icon="icons.play" />
      </b-button>
    </b-overlay>
    <b-modal v-model="show_data" modal-class="log-cell-modal" title="протокол вызова" size="xl" lazy ok-title="Закрыть">
      <obj-form
        v-model="call_info"
        :description="call_info._description"
        mode="view"
      />
      <b-checkbox v-model="show_info_additional" variant="outline-obitless">
        дополнительно
      </b-checkbox>

      <b-table
        :items="call_info_table"
        v-if="show_info_additional"
        responsive
      />
      <b-table
        :items="log_sorted"
        responsive
        :fields="show_info_additional ? null : format_table"
      />
    </b-modal>
  </div>
</template>

<script>
import { faHistory } from "@fortawesome/free-solid-svg-icons";
import { EventBus } from "..";
import { as_icon, Icon } from "../layot";
import CellVue from "../mixins/Cell.vue";
import { timeformatter, timestamp } from "../../formatters";
import ObjForm from "../ObjForm.vue";
import { sortBy } from "lodash-es";
import inputPlayerVue from "./inputPlayer.vue";

const actions = {
  local: "Обработка",
  incoming: "Поступление звонка",
  outgoing: "Звонок",
};

export default {
  name: "LogCell",
  mixins: [CellVue, EventBus],
  components: { Icon, ObjForm },
  data() {
    return {
      log: [],
      show_info_additional: false,
      show_data: false,
      loading: false,
    };
  },
  computed: {
    format_table: () => [
      { label: "Время", key: "date", formatter: (v) => timestamp(new Date(v)) },
      { label: "Событие", key: "direction", formatter: (v) => actions[v] || v },
      { label: "Обработчик", key: "exten" },
    ],
    icons: () => ({
      play: as_icon(faHistory),
    }),
    call_info_table() {
      return Object.entries(this.item._raw);
    },
    log_sorted() {
      return sortBy(this.log, (o) => new Date(o.date));
    },
    call_info() {
      let ret = {
        time: timestamp(this.item.date),
        len: timeformatter(this.item.len),
        from: this.item.from,
        to: this.item.to,
        operator: "Оператор 1",
        audio: this.item.call_id,
        tags: "",
        _description: {
          fields: {
            time: "Время",
            len: "Длительность",
            from: "Кто",
            to: "Кому",
            operator: { label: "Оператор" },
            audio: {
              label: "Аудиозапись",
              comp: inputPlayerVue,
              row_props: {},
            },
            tags: "Теги",
          },
        },
      };
      return ret;
    },
  },
  methods: {
    show(val) {
      this.show_data = val;
      if (val) {
        this.loading = true;
        this.$store.dispatch("call/get_log", this.item.call_id).then(
          (ret) => {
            this.log = ret;
            this.loading = false;
          },
          () => {
            this.loading = false;
          }
        );
      }
    },
  },
};
</script>

<style lang='scss'>
.log-cell {
  padding: unset;
  .svg-inline--fa {
    vertical-align: middle;
  }
}
.field_log {
  width: 50px;
}
.log-cell-modal {
  .btn-outline-obit {
    display: none;
  }
}
</style>