import { cloneDeep, merge, filter, pick } from 'lodash-es';
import { eventBus } from '../components';
import { SERVER } from '../constants/index';
import { obj_template, resolveWithPromise, type_val } from '../functions';

const build_state = (type, descr, test_data = []) => ({
  description: descr,
  obj_type: type,
  objects: test_data,
  last_update: 0,
  load_status: null,
})

const actions = ['get', 'add', 'update', 'list', 'delete']

function rebuild_object(obj, description) {
  const fields = description.fields || {}
  const with_targets = Object.entries(fields).reduce((r, [field, field_descr]) => {
    if (type_val(field_descr) == 'Object' && field_descr.ret_target !== undefined) {
      return Object.assign(r, { [field_descr.ret_target]: field })
    }
    return r
  }, {})
  let ret = merge({}, obj)
  Object.entries(with_targets).map(([target, field]) => {
    ret[field] = ret[target]
    delete ret[target]
  })
  return ret
}

const build_storage = (type, descr, test_data = [], options = {}) => merge({
  namespaced: true,
  state: () => build_state(type, descr, test_data),
  getters: {
    base_url: () => SERVER,
    objects: (state, getters) => {
      let objs = state.objects
      if (getters.description.unique_for) {
        objs = filter(objs, getters.unique_for)
      }
      return cloneDeep(objs.map(r => getters.restore_object(cloneDeep(r))))
    },
    urls: (state, getters) => {
      return getters.description?.urls ?? {}
    },
    action_method: (state, getters) => (method) => {
      return (getters.description?.action_methods ?? {})[method] ?? 'POST'
    },
    description: (state) => state.description,
    has_api: (state, getters) => {
      const urls = getters.urls
      return actions.reduce(
        (r, a) => merge(r, { [a]: state.description[`custom_${a}`] || urls[a] || false }),
        {}
      )
    },
    object: (state, getters) => object_id => {
      const key = state.description.obj_key
      let filtered = getters.objects.filter(
        row => `${row[key]}`.toLowerCase() == `${object_id}`.toLowerCase()
      )
      return filtered.length ? filtered[0] : null
    },
    last_update: state => state.last_update,
    row_data: state => obj => ({
      obj_id: obj[state.description.obj_key],
      obj_key: obj[state.description.obj_key],
      obj_type: state.obj_type
    }),
    calced_options: () => () => ({}),
    restore_object: (state, getters, rootState, rootGetters) => (obj) => {
      if (obj === undefined) {
        return {}
      }
      const description = state.description
      let additional_data = {
        obj_type: description.obj_type,
        obj_key: obj[description.obj_key],
        obj_id: obj[description.obj_key],
        obj_name: obj[description.obj_key],
        _ready: true,
        _description: description,
        _related: (key) => {
          if (description.fields[key]) {
            const related_type = description.fields[key].related
            const multiple = description.fields[key].multiple
            if (!related_type) { throw Error(`${key} is not related key for ${obj.obj_type}`) }
            let ret
            if (!multiple) ret = rootGetters[`${related_type}/object`](obj[key])
            else {
              const keys = obj[key] || []
              ret = rootGetters[`${related_type}/objects`].filter(
                obj => keys.indexOf(obj.obj_key) >= 0
              )
            }
            return ret
          }
          return undefined
        },
      }
      let ret = merge({}, obj, additional_data, getters.test_data)
      ret['obj_name'] = obj_template(ret, state.obj_type)
      ret = merge(ret, getters.calced_options(ret))
      return ret
    },
    api_data: (state, getters, rootState, rootGetters) => {
      const def_data = () => {
        let r = getters.unique_for
        return r
      }
      return actions.reduce(
        (r, a) => merge(r, { [a]: state.description[`api_data_${a}`] || def_data(state, getters, rootState, rootGetters) }),
        {}
      )
    },
    unique_for: (state, getters, rootState, rootGetters) => {
      let ret = {}
      if (getters.description.unique_for) {
        if (type_val(getters.description.unique_for) == 'String') {
          const key = getters.description.unique_for_key || `${getters.description.unique_for}_id`
          ret[key] = rootGetters[`current_${getters.description.unique_for}`]
        } else if (type_val(getters.description.unique_for) == 'Array') {
          for (const u of getters.description.unique_for) {
            const key = `${u}_id`
            ret[key] = rootGetters[`current_${u}`]
          }
        }
      }
      return ret
    },
    need_update: (state, getters) => (force = false) => getters.last_update == 'loading' || (!force && (Date.now() - getters.last_update) < 5000),
    pre_data: (state, getters) => (data, type = 'list') => {
      let for_type = getters[`pre_data_${type}`] ? getters[`pre_data_${type}`](data) : {}
      let ret = merge({}, data, getters.unique_for, for_type)
      return ret
    },
    test_data: (state, getters) => Object.entries(getters.description.fields).reduce(
      (r, [field, field_description]) => {
        if (field_description && (field_description.test_value !== undefined)) {
          r[field_description.target || field] = field_description.test_value
        }
        return r
      }, {}
    ),
    post_data: (state, getters) => (data, type = 'list') => {
      let for_type = getters[`post_data_${type}`] ? getters[`post_data_${type}`](data) : {}
      // допишем тестовые данные если есть
      let test_data = getters.test_data
      let ret = merge({}, test_data, data, getters.unique_for, for_type)
      return ret
    },
    other_related: (state, getters) => {
      return getters.description?.related ?? [];
    },
    related_objects: (state, getters) => {
      let ret = []
      if (type_val(getters.description.unique_for) == 'String') {
        ret = [getters.description.unique_for]
      } else {
        ret = getters.description.unique_for
      }
      return ret
    }
  },
  mutations: {
    reset: (state) => Object.assign(state, build_state(type, descr, test_data)),
    load: (state, objects) => {
      state.last_update = Date.now()
      let uids = []
      const key = state.description.obj_key
      const loaded_objects = objects.map(row => {
        const r = merge({}, rebuild_object(row, state.description), {
          obj_id: `${row[key]}`,
          obj_key: `${row[key]}`,
          obj_type: state.obj_type
        })
        uids.push(r.obj_id.toLowerCase())
        return r
      }
      )
      state.objects = state.objects.filter(o => uids.indexOf(o[key]?.toLowerCase()) == -1).concat(loaded_objects)
    },
    update: (state, object) => {
      const obj = rebuild_object(object, state.description)
      const key = state.description.obj_key
      const obj_key = obj[key] || obj.obj_id
      const old_obj = state.objects.find(o => o[key].toLowerCase() === obj_key.toLowerCase()) || {}

      //Костыль, т.к. при удалении rules из массива в расписании при вызове merge ниже актуальный state мерджится со старым
      //и в итоге получается поле rules без удаления и не происходит обновление страницы
      if (old_obj.rules) delete old_obj.rules

      const upd_obj = merge(
        old_obj,
        obj
      )

      state.objects = state.objects.filter(
        o => o[key].toLowerCase() != obj_key.toLowerCase()
      ).concat([upd_obj])
      eventBus.$emit(`updated:${upd_obj.obj_type}:${upd_obj.obj_id}`)
    },
    delete: (state, object_id) => {
      const key = state.description.obj_key
      state.objects = state.objects.filter(obj => obj[key].toLowerCase() != object_id.toLowerCase())
    },
    outdate: (state) => state.last_update = 0,
    load_status: (state, status) => state.load_status = status,
  },
  actions: {
    get_related({ dispatch, getters }) {
      //const rel = getters.related_objects || []
      const rel = getters.other_related
      return Promise.all(rel.map(
        o => dispatch(`${o}/list`, null, { root: true })
      )
      )
    },
    list({ getters, commit, dispatch }, data = {}) {
      const conf = data || {}
      const force = conf.force || false
      const list_api = getters.has_api.list
      const method = getters.action_method('list')
      const base_url = getters.base_url
      if (!list_api || getters.need_update(force)) {
        return resolveWithPromise(getters.objects)
      } else if (type_val(list_api) == 'String') {
        commit('load_status', 'loading')
        return dispatch('get_related').then(() => dispatch(
          'requestApi', { url: list_api, data: getters.pre_data(data, 'list'), method, base_url }, { root: true }).then(
            d => {
              if (d?.data?.result == 'success') {
                commit('load', d.data.payload.map(r => getters.post_data(r, 'list')))
                return Promise.resolve(d.data.payload)
              }
              commit('load_status', 'error')
              return Promise.resolve(d)
            }

          ))
      }
    },
    get({ commit, getters, dispatch }, object_id) {
      const get_api = getters.has_api.get

      const base_url = getters.base_url
      if (!get_api) {
        return dispatch('list').then(() => getters.object(object_id))
      } else if (type_val(get_api) == 'String') {
        return dispatch('get_related').then(() => dispatch('requestApi', {
          url: get_api,
          data: getters.pre_data({
            [getters.description.obj_key]: object_id
          }, 'get'), base_url
        }, { root: true }).then(
          d => {
            if (d.data.result == 'success') {
              commit('update', getters.post_data(d.data.payload, 'get'))
              return d.data.payload
            }
          }
        ))
      }
    },
    add({ getters, commit, dispatch }, object) {
      const get_api = getters.has_api.add

      const base_url = getters.base_url
      let add_resp = null
      if (!get_api) {
        commit('update', object)
        return resolveWithPromise(object)
      } else {
        const add_data = getters.pre_data(object, 'add')
        return dispatch(
          'requestApi',
          {
            url: get_api,
            data: add_data,
            base_url,
            withFiles: getters.description.withFiles || false
          }, { root: true }
        ).then(
          d => {
            if (d?.isAxiosError) {
              commit('add_message', { message: d.response.data.text, type: 'danger' }, { root: true });
              return null
            }
            if (d.data.result == 'success') {
              const record = getters.post_data(d.data.payload, 'add')
              commit('update', record)
              commit('add_message', { type: 'success', message: getters.description.create_text || `Успешно создан ${getters.description.verbose}` }, { root: true })
              eventBus.$emit(`added:${getters.description.obj_type}`, record)
              add_resp = record
            } else {
              commit('add_message', { type: 'danger', message: getters.description.error_text ? getters.description.error_text(d) : `Ошибка при создании ${getters.description.verbose}` }, { root: true })
            }
            return d.data.payload
          }
        ).then(() => dispatch('list')).then(() => add_resp)
      }
    },
    update({ getters, commit, dispatch }, object) {
      const get_api = getters.has_api.update
      const obj_id = object[getters.description.obj_key];
      const base_url = getters.base_url
      if (!get_api) {
        commit('update', object)
        return resolveWithPromise(getters.object(object.obj_id))
      } else {
        return dispatch(
          'requestApi',
          {
            url: get_api,
            data: getters.pre_data(object, 'update'), base_url
          }, { root: true }
        ).then(
          resp => {
            if (resp.data.result == 'success') {
              commit('add_message', { type: 'success', message: getters.description.update_text || `Успешно обновлен ${getters.description.verbose}` }, { root: true })
            }
            return dispatch('get', obj_id)
          }
        )
      }
    },
    delete({ getters, commit, dispatch }, obj_id) {
      const remove_api = getters.has_api.delete
      const obj = pick(getters.object(obj_id), [getters.description.obj_key])

      const base_url = getters.base_url
      if (!remove_api) {
        return commit('delete', obj)
      } else {
        return dispatch(
          'requestApi',
          {
            url: remove_api,
            data: getters.pre_data(obj, 'delete'),
            base_url
          },
          { root: true }
        ).then(
          r => {
            if (r.data.result == 'success') {
              let d = getters.post_data(r.data.payload, 'delete')
              commit('add_message', { type: 'success', message: getters.description.delete_text || `Успешно удален ${getters.description.verbose}` }, { root: true })
              commit('delete', obj_id)
              eventBus.$emit(`deleted:${d.obj_type}:${d.obj_id}`, d)
            }
          }
        ).then(() => dispatch('list'))
      }
    }
  }
}, options)


export { build_storage, rebuild_object }