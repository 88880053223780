<template>
  <div :class="['archive', obj_type, keyword]">
    <slot v-bind="{ filter: additional_filter, fetch, update_add_filter }" />
    <b-overlay :show="loading" class="overlay" variant="transparent">
      <template #overlay>
        <div class="sp">
          <spinner overlay />
        </div>
      </template>
      <base-list
        :description="real_description"
        :objects="items"
        ref="table"
        :use_paginator="false"
        noLocalSorting
        @sorted="fetch()"
      />
    </b-overlay>

    <!-- TODO подключить <TablePagination> -->
    <b-row
      style="background-color: #f8f9fa; padding-top: 15px; padding-left: 15px"
      v-if="showPaginator"
    >
      <b-col xl="4" cols="6">
        <b-pagination
          :per-page="per_page"
          v-model="page"
          :total-rows="count"
          class="obit-paginator"
        />
      </b-col>
      <b-col>
        <ul class="pagination obit-paginator b-pagination pagination-sm">
          <li class="text">Показать пo</li>
          <li
            v-for="(v, i) in per_pages_variants"
            :key="i"
            :class="['page-item', { active: per_page == v }]"
          >
            <button class="page-link" @click="per_page = v">
              {{ v }}
            </button>
          </li>
        </ul>
      </b-col>
    </b-row>
    <!-- финиш TablePagination -->
  </div>
</template>

<script>
import { merge } from "lodash-es";
import { OBJ_COMPONENTS } from "../../object";
import BaseList from "../BaseList.vue";
import Spinner from "../obit/Spinner.vue";

export default {
  name: "ArchiveList",
  components: { BaseList, Spinner },
  props: {
    obj_type: {
      type: String,
      required: false,
    },
    description: {
      type: Object,
      required: false,
      default: null,
    },
    action: {
      type: String,
      required: false,
      default: null,
    },
    filter_func: {
      type: Function,
      required: false,
      default: () => [],
    },
    keyword: {
      required: true,
      type: String,
    },
    per_pages_variants: {
      required: false,
      type: Array,
      default: () => [10, 30, 100],
    },
  },
  data() {
    return {
      mounted: false,
      items: [],
      filter: {},
      additional_filter: [],
      per_page: 10,
      page: 1,
      count: 0,
      loading: false,
    };
  },
  computed: {
    real_description() {
      if (!this.obj_type && !this.description) {
        throw Error("Не предано описание или тип объекта");
      }
      return this.obj_type ? OBJ_COMPONENTS[this.obj_type] : this.description;
    },

    table_props() {
      let ret = {};
      ret["per-page"] = this.per_page;
      ret["current-page"] = this.page;
      return ret;
    },
    filter_data() {
      const start = this.page == 1 ? 0 : (this.page - 1) * this.per_page;
      const sortOpts = this.sortOptions() || {};
      let data_to_send = {
        count: this.per_page,
        from: start,
        ...this.filter_func(),
        ...sortOpts,
      };
      if (this.additional_filter.length) {
        let filter = data_to_send.filter ?? [];
        filter = filter.concat(this.additional_filter);
        data_to_send.filter = filter;
      }

      return data_to_send;
    },
    storage_action() {
      return this.action || `${this.obj_type}/list`;
    },

    showPaginator() {
      return (
          this.count >= Math.min(...this.per_pages_variants)
      );
    },
  },
  mounted() {
    this.mounted = true;
    this.fetch();
    this.$watch(
      () => JSON.stringify([this.obj_type, this.keyword]),
      () => {
        this.filter = {};
        this.page = 1;
      }
    );
    this.$watch("page", this.fetch);
    this.$watch("per_page", this.fetch);
    this.$watch("keyword", () => {
      this.page = 1;
      this.fetch();
    });
  },
  methods: {
    sortOptions() {
      let ret = {};
      if (this.mounted) {
        const table = this.$refs["table"];
        const sortOpts = {
          sortBy: table.sortBy || null,
          sortDesc: table.sortDesc || false,
        };
        const fields = table.fields;
        const sortFieldsReplaceDict = fields
          .filter((r) => r.sortable)
          .reduce(
            (r, l) =>
              merge(r, {
                [l.key]: l?.cell_options?.apiKey || l.apiKey || l.key,
              }),
            {}
          );
        if (sortOpts.sortBy) {
          ret["order"] =
            sortFieldsReplaceDict[sortOpts.sortBy] || sortOpts.sortBy;
          ret["direction"] = sortOpts.sortDesc ? "desc" : "asc";
        }
      }
      return ret;
    },
    fetch() {
      // постпроим уникальные и неро
      this.loading = true;
      this.$store
        .dispatch(this.storage_action, {
          raw: true,
          force: true,
          data: this.filter_data,
        })
        .then((r) => this.on_success(r));
    },
    on_success(resp) {
      this.items = resp?.items ?? [];
      const count = resp?.total ?? 0;
      this.count = count;

      this.loading = false;
      this.$emit("update_count", count);
    },
    update_add_filter(to = []) {
      this.additional_filter = to;
    },
  },
};
</script>

<style lang='scss'>
.archive {
  .overlay {
    max-width: 760px;
    min-height: 300px;
    margin-bottom: 1em;
  }
  & .form-inline {
    .form-group {
      margin-bottom: 0;
    }
    .btn {
      margin-right: 0;
    }
    @media screen and (max-width: 800px) {
      padding-left: 10px;
      padding-right: 10px;
    }
    & > .col {
      flex-grow: 0;

      &:not(:first-child, :last-child) {
        margin-right: 5px !important;
        padding: 0px 2px;
      }
    }
  }
  .archive-spinner {
    position: absolute;
    width: 100%;
    height: 100%;
    &.blur {
      backdrop-filter: blur(2px);
      z-index: 90;
    }
  }
  .form-inline {
    padding-top: 25px;
    padding-bottom: 7px;
    max-width: 930px;
    justify-content: space-between;
  }
  .btn-outline-export {
    font-weight: bold;
    font-size: 13px;
    letter-spacing: 0.05em;
  }
  .table {
    min-width: 660px;
    overflow: scroll;
  }
  .btn-outline-primary:not(:disabled):not(.disabled).active {
    color: white;
  }
  .btn-filter {
    padding: 12px;
    padding-top: 6px;
    padding-bottom: 6px;
    margin-right: 30px;
    font-weight: bold;
    font-size: 13px;
  }
  .search-input {
    font-size: 14px;
  }

  @media (max-width: 576px) {
    .archive {
      .form-inline {
        padding-top: 20px;
        padding-bottom: 0px;
      }
    }
  }

  @media (min-width: 992px) and (max-width: 1130px) {
    .search-input {
      width: 193px !important;
    }
  }

  @media (max-width: 992px) {
    .justify-content-end {
      visibility: hidden;
    }
  }
  @media (min-width: 576px) {
    .col-sm-4 {
      max-width: 47.33333%;
    }
  }
  @media (min-width: 992px) {
    .col-lg-3 {
      max-width: 33%;
    }
  }
}
</style>