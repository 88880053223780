<template>
  <div fluid="sm" :class="['week-input', { disabled: disabled }]">
    <template v-if="disabled">
      <div class="rule-row" v-for="(item, index) in val" :key="index">
        <span v-if="!item.dayOfWeek.fullRange" class="subrule day-of-week">
          {{ item.dayOfWeek.fromDay | day_of_week }} -
          {{ item.dayOfWeek.toDay | day_of_week }}</span
        >
        <span v-if="!item.timeRange.fullRange" class="subrule timerange"
          >с {{ item | from_format }} до {{ item | to_format }}</span
        >
        <span v-else class="subrule timerange">Круглосуточно</span>
      </div>
    </template>

    <template v-else>
      <div
        v-for="(item, index) in val"
        :key="item.id"
        :class="[
          'rule-row',
          item.state,
          { 'igonore-rule': is_rule_ignored(index) },
        ]"
      >
        <template v-if="item.state != 'delete'">
          <b-form-group label=" ">
            <div class="info-block">
              <b-input-group class="groups groups-trash">
                <b-input-group-prepend>
                  <b-button
                    variant="outline-obit"
                    class="border-0 groups-btn"
                    @click.prevent="delete_rule(index)"
                    ><obit-icon icon="trash"
                  /></b-button>
                </b-input-group-prepend>
              </b-input-group>
              <b-input-group
                :class="{
                  groups: true,
                  'groups-clock': () =>
                    val[index].dayOfWeek.fromDay &&
                    val[index].timeRange.fullRange,
                }"
              >
                <div class="groups-block">
                  <b-input-group-text>с</b-input-group-text>
                  <b-timepicker
                    :value="onTimeFrom(index)"
                    @input="onTimeFrom(index, $event)"
                    locale="ru-RU"
                    placeholder="00:00"
                    no-close-button
                    class="groups-timepiker"
                    :disabled="val[index].timeRange.fullRange"
                  />
                  <span class="p-group p-group-mobile">-</span>
                  <b-input-group-text class="input-groups-right"
                    >по</b-input-group-text
                  >
                  <b-timepicker
                    :value="onTimeTo(index)"
                    @input="onTimeTo(index, $event)"
                    locale="ru-RU"
                    placeholder="00:00"
                    no-close-button
                    class="groups-timepiker"
                    :disabled="val[index].timeRange.fullRange"
                  />
                </div>

                <div>
                  <b-form-checkbox
                    v-model="val[index].timeRange.fullRange"
                    @input="onFullRangeChange(index, 'timeRange', $event)"
                    >круглосуточно</b-form-checkbox
                  >
                </div>
              </b-input-group>

              <b-input-group
                :class="{
                  groups: true,
                  'groups-day': () =>
                    val[index].dayOfWeek.fromDay &&
                    val[index].timeRange.fullRange,
                }"
              >
                <div class="groups-block">
                  <b-form-select
                    :options="days_options | fromDays(val[index].dayOfWeek)"
                    :disabled="val[index].dayOfWeek.fullRange"
                    :value="get_by_key(index, 'dayOfWeek.fromDay')"
                    @input="set_by_key(index, 'dayOfWeek.fromDay', $event)"
                  />
                  <span class="p-group">-</span>
                  <b-form-select
                    :options="days_options | toDays(val[index].dayOfWeek)"
                    :disabled="val[index].dayOfWeek.fullRange"
                    :value="get_by_key(index, 'dayOfWeek.toDay')"
                    @input="set_by_key(index, 'dayOfWeek.toDay', $event)"
                  />
                </div>
                <div>
                  <b-form-checkbox
                    v-model="val[index].dayOfWeek.fullRange"
                    @input="onFullRangeChange(index, 'dayOfWeek', $event)"
                    >все дни</b-form-checkbox
                  >
                </div>
              </b-input-group>
              <!-- <b-form-text v-if="is_rule_ignored(index)"
              >Правило будет проигнорировано</b-form-text
            > -->
            </div>
            <!-- <div class="groups-checkbox"></div> -->
          </b-form-group>
        </template>
      </div>
    </template>
    <b-button
      variant="outline-primary"
      size="sm"
      @click="add_rule"
      v-if="!disabled"
    >
      + Добавить</b-button
    >
  </div>
</template>


<script>
// Сделать v-for цикл в формах таймпикера и добавить кнопку
import { InputMixin } from "../../mixins";
import { timeformatter } from "../../../formatters";
// import { cloneDeep, merge, pick } from "lodash-es";
import { merge } from "lodash-es";
import { as_icon, Icon } from "../../layot";
import cloneDeep from "lodash/cloneDeep";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { ObitIcon } from "../../obit";
import { set, get } from "shvl";
import {uuidv4} from "@/functions";
const days = ["Пн", "Вт", "Ср", "Чт", "Пт", "Сб", "Вс"].reduce(
  (r, t, i) => r.concat([{ value: i + 1, text: t }]),
  []
);
const day_of_weeks = days.reduce(
  (r, item) => merge(r, { [item.value]: item.text }),
  {}
);
const opt_types = {
  timeRange: "Время",
  dayOfWeek: "Дни недели",
  dayOfMonth: "Дни месяца",
  month: "Месяца",
  year: "Года",
};

const item_time_format = (item, direction = "from") =>
  timeformatter(
    item.timeRange[`${direction}Hour`] * 60 * 60 +
      item.timeRange[`${direction}Minute`] * 60
  );

export default {
  name: "weekInput",
  mixins: [InputMixin],
  components: {
    Icon,
    ObitIcon,
  },
  data() {
    return {
    };
  },
  filters: {
    from_format: (item) => item_time_format(item).slice(0, 5),
    to_format: (item) => item_time_format(item, "to").slice(0, 5),
    day_of_week: (val) => day_of_weeks[val] || "--",

    fromDays: (days, val) => {
      const toDayVal = val.toDay
      if (toDayVal) {
        return days.filter((el) => el.value <= toDayVal)
      }
      return days
    },

    toDays: (days, val) => {
      const fromDayVal = val.fromDay
      if (fromDayVal) {
        return days.filter((el) => el.value >= fromDayVal)
      }
      return days
    }
  },

  computed: {
    icons: () => ({ trash: as_icon(faTrash) }),
    days_options: () => days,
  },

  methods: {
    get_by_key(index, target) {
      return get(this.val[index], target);
    },
    set_by_key(index, target, to) {
      let new_state = this.change_state(index);

      set(new_state[index], target, to);
      this.val = new_state;
    },
    is_rule_ignored(index) {
      const keys = Object.keys(opt_types);
      const rule = this.val[index];
      return keys.every((k) => rule[k].fullRange);
    },
    add_rule() {
      const new_rule = Object.keys(opt_types).reduce(
        (r, k) => merge(r, { [k]: { fullRange: true } }),
        { state: "new", id: uuidv4() }
      );
      this.val = this.val ? this.val.concat([new_rule]) : [new_rule];
    },

    onFullRangeChange(index, key, value) {
      if (value) {
        this.setToFull(index, key);
      } else {
        this.setToUnFull(index, key);
      }
    },
    setToFull(index, key) {
      let new_val = this.change_state(index);
      if (new_val[index + 1]) {
        delete new_val[index + 1][key]["fullRange"];
        new_val[index][key] = { fullRange: true };
      }

      this.val = new_val;
    },
    setToUnFull(index, key) {
      let new_val = this.change_state(index);

      if (new_val[index + 1]) {
        new_val[index + 1][key] = { fullRange: true };
        new_val[index][key] = { fullRange: false };
      }

      this.val = new_val;
    },
    clone_index(index) {
      let line = cloneDeep(this.val[index]);
      line.state = "delete";
      line.id = uuidv4();
      let new_lines = [
        ...this.val.slice(0, index + 1),
        line,
        ...this.val.slice(index + 1),
      ];
      new_lines[index].state = "new";
      this.val = new_lines;
      return new_lines;
    },

    change_state(index) {
      let ret = [...this.val];

      if (this.val[index]?.state == "old") {
        ret = this.clone_index(index);
      }
      return ret;
    },
    delete_rule(index) {
      let new_val = cloneDeep(this.val);
      if (new_val[index].state == "new") {
        new_val = new_val.filter((v, i) => i != index);
      } else if (new_val[index].state == "old") {
        new_val[index].state = "delete";
      }
      this.val = new_val;
    },
    onTimeFrom(index, item) {
      if (!item) {
        if (!this.val[index].timeRange.fullRange) {
          const hour = parseInt(this.val[index].timeRange.fromHour);
          const minute = parseInt(this.val[index].timeRange.fromMinute);
          return timeformatter(hour * 60 * 60 + minute * 60);
        } else {
          return null;
        }
      } else {
        this.change_state(index);
        const from = item.split(":");
        this.val[index].timeRange.fromHour = parseInt(from[0]);
        this.val[index].timeRange.fromMinute = parseInt(from[1]);
        if (this.val[index].timeRange.fullRange) {
          delete this.val[index].timeRange.fullRange;
        }
      }
    },
    onTimeTo(index, item) {
      if (!item) {
        if (!this.val[index].timeRange.fullRange) {
          const hour = parseInt(this.val[index].timeRange.toHour);
          const minute = parseInt(this.val[index].timeRange.toMinute);
          return timeformatter(hour * 60 * 60 + minute * 60);
        } else return null;
      } else {
        this.change_state(index);
        const to = item.split(":");
        this.val[index].timeRange.toHour = parseInt(to[0]);
        this.val[index].timeRange.toMinute = parseInt(to[1]);
        if (this.val[index].timeRange.fullRange) {
          delete this.val[index].timeRange.fullRange;
        }
      }
    },

  },
};
</script>

<style  lang='scss'>
fieldset.form-group.rules {
  .form-row {
    // display: flex;
    // flex-direction: column;

    .bv-no-focus-ring {
      max-width: 100%;
    }
    .info-block {
      display: flex;
      flex-direction: row-reverse;
      align-items: baseline;
      @media (max-width: 640px) {
        flex-flow: wrap-reverse;
        justify-content: end;
        max-width: 274px;
      }
    }
    .groups {
      display: flex;
      flex-direction: column;
      width: auto;
      .groups-block {
        display: flex;
        .input-groups-right {
          @media (max-width: 640px) {
            padding-left: 12px;
          }
        }
      }
      .p-group {
        padding: 11px;
      }
      .p-group-mobile {
        display: none;
        @media (max-width: 640px) {
          display: flex;
          align-items: center;
        }
      }
      .groups-btn {
        font-size: 16px;
        @media (max-width: 640px) {
          padding: 0;
        }
      }
    }
    @media (max-width: 640px) {
      .groups-day {
        margin-right: 12px;
        order: 1;
      }
      .groups-trash {
        position: absolute;
        right: 0;
        bottom: 102px;
      }
    }

    .groups-clock {
      @media (max-width: 640px) {
        order: 1;
      }
    }
    // .groups-day {
    //   margin: 0 40px 0 0;
    // }
    // .groups-disabled {
    //   margin: 0;
    // }
    .groups-timepiker {
      display: flex;
      align-items: center;
    }
    .groups-checkbox {
      display: flex;
      justify-content: space-between;
    }
  }
  .custom-checkbox {
    display: flex;
    justify-content: flex-end;
    padding: 20px 0;
    // width: 50%;
    @media (max-width: 640px) {
      padding: 22px;
      justify-content: end;
      width: 124px;
    }
  }

  .b-form-btn-label-control.form-control > label {
    display: flex;
    align-items: center;
  }
  .custom-select {
    width: 100px;
  }
  .input-group-text {
    background: transparent;
    border: none;

    @media (max-width: 640px) {
      padding-left: 0;
      display: none;
    }
  }
}
.week-input {
  padding-left: 10px;
  .rule-row:not(.delete) {
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 10px;
    margin-bottom: 10px;
    .subrule {
      margin-right: 1em;
    }
  }
}
</style>
