<template>
  <b-row id="settings">
    <b-col class="settings">
      <portal-target class="header" name="header">
        <span v-if="header" class="text">{{ header }}</span>
        <portal-target
          v-if="!is_vertical.value"
          name="create_button"
          class="ml-auto create-button"
        />
      </portal-target>

      <b-button
        class="settings__but-back"
        v-if="show_button_back"
        variant="outline-obitless"
        :to="link([], -quantity_steps_back)"
      >
        <obit-icon icon="back" /> {{ text_button_back }}
      </b-button>

      <scrollable class="scroll-menu-hor" ref="scroll">
        <b-nav
          tabs
          v-if="this.showLinks"
          :class="['second-menu', { right: right }]"
        >
          <b-nav-item
            v-if="!show_left"
            @click="slideleft"
            class="nav-scroll left"
            ><icon :icon="icons.left"
          /></b-nav-item>
          <b-nav-item class="invisible_el"
            ><span v-visible="toggle_left" ref="el_l"
          /></b-nav-item>
          <b-nav-item
            v-for="(l, indx) in links"
            v-bind:key="indx"
            v-bind="nav_bind(l.link, indx)"
          >
            {{ l.text }}
            <obit-icon
              v-if="l.description"
              icon="question"
              v-b-tooltip.v-light="l.description"
            />
          </b-nav-item>
          <b-nav-item class="invisible_el">
            <span v-visible="toggle_right" ref="el_r"
          /></b-nav-item>
          <b-nav-item
            v-if="!show_right"
            @click="slideright"
            class="nav-scroll right"
            ><icon :icon="icons.right"
          /></b-nav-item>
        </b-nav>
      </scrollable>
      <router-view />
    </b-col>
  </b-row>
</template>

<script>
// @ is an alias to /src
import { PortalTarget } from "portal-vue";
import Scrollable from "simplebar-vue";
import { as_icon, Icon } from "../components/layot";
import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import { BNav, BNavItem, VBVisible } from "bootstrap-vue";
import _ from "lodash";
import ObitIcon from "../components/obit/ObitIcon.vue";

export default {
  name: "SettingsView",
  components: {
    PortalTarget,
    Scrollable,
    Icon,
    BNav,
    BNavItem,
    ObitIcon,
  },
  inject: ["link", "is_vertical"],
  directives: {
    visible: VBVisible,
  },
  mounted() {
    this.checkLinks();
    this.$watch(
      () => JSON.stringify(this.links),
      () => {
        this.show_left = true;
        this.show_right = true;
        this.checkLinks();
      }
    );
  },
  props: {
    ignoreMeta: {
      required: false,
      default: false,
      type: Boolean,
    },
    links: {
      required: false,
      default: () => [
        { link: ["employee"], text: "Пользователи" },
        { link: ["group"], text: "Группы" },
        { link: ["role"], text: "Роли" },
        { link: null, text: "История событий" },
      ],
    },
    header: {
      required: true,
      default: null,
      type: String,
    },
    show_button_back: {
      type: Boolean,
      default: false,
    },
    text_button_back: {
      type: String,
      default: "Назад",
    },
    quantity_steps_back: {
      type: Number,
      default: 1,
    },
    right: {
      default: false,
      type: Boolean,
      required: false,
    },
  },
  data() {
    return {
      disabled_options: {},
      options_options: {},
      show_left: true,
      show_right: true,
      loading: false,
      tab: 0,
      scroll_pos: 0,
    };
  },
  computed: {
    showLinks() {
      let ret = !!this.links.length;
      if (!ret) {
        return false;
      }
      //!!links.length && !ignoreMeta && !$route.meta.isObj
      let isObj = !!this.$route?.meta?.isObj;
      if (!this.ignoreMeta) {
        ret = ret ^ isObj;
      }
      return ret;
    },
    link_depth() {
      return !this.ignoreMeta && this.$route.meta.isObj ? -2 : -1;
    },
    icons: () => ({
      left: as_icon(faChevronLeft),
      right: as_icon(faChevronRight),
    }),
    scroll_state() {
      return this.$refs["scroll"].$refs["scrollElement"].scrollLeft;
    },
  },
  methods: {
    checkLinks() {
      // reset
      this.disabled_options = {};
      this.$forceUpdate();
      let promises = [];
      for (const link_id in this.links) {
        const link = this.links[link_id];
        if (link.check_disabled) {
          this.disabled_options[link_id] = true;
          promises.push(
            link.check_disabled(this).then((r) => {
              this.disabled_options[link_id] = r;
              this.$forceUpdate();
            })
          );
        }
      }
      if (promises.length) {
        Promise.all(promises).then(() => {
          this.toggle_left();
          this.toggle_right();
        });
      }
      this.toggle_left();
      this.toggle_right();
    },
    slideright() {
      let a = document.querySelector(
        "#page-wrapper > div.simplebar-wrapper > div.simplebar-mask > div > div > div > div > div > div > div.scroll-menu-hor > div.simplebar-wrapper > div.simplebar-mask > div > div"
      );
      this.scroll_pos = this.scroll_pos + 0.2 * window.innerWidth;
      a.scrollLeft = this.scroll_pos;
    },
    slideleft() {
      let a = document.querySelector(
        "#page-wrapper > div.simplebar-wrapper > div.simplebar-mask > div > div > div > div > div > div > div.scroll-menu-hor > div.simplebar-wrapper > div.simplebar-mask > div > div"
      );
      this.scroll_pos =
        this.scroll_pos > 0.2 * window.innerWidth
          ? this.scroll_pos - 0.2 * window.innerWidth
          : 0;
      a.scrollLeft = this.scroll_pos;
    },
    nav_bind(to, indx) {
      if (to) {
        let link = this.link(to, this.link_depth);
        let ret = { to: link, active: this.$route.path.startsWith(link) };
        if (this.disabled_options[indx]) {
          ret.disabled = true;
        }
        return ret;
      } else {
        return { disabled: true };
      }
    },
    toggle_left: _.debounce(function (value = null) {
      if (value !== null) {
        this.show_left = value;
      } else {
        this.show_left = this.$refs.el_l?.checkVisibility();
      }
    }, 200),
    toggle_right: _.debounce(function (value = null) {
      if (value !== null) {
        this.show_right = value;
      } else {
        this.show_right = this.$refs.el_r?.checkVisibility();
      }
    }),
  },
};
</script>
<style lang='scss'>
#settings {
  header {
    text {
      letter-spacing: 0.05em;
    }
  }

  // .header.vue-portal-target {
  //   position: sticky;
  //   top: 0;
  //   background-color: #fff;
  //   z-index: 10;
  // }
  // .scroll-menu-hor {
  //   position: sticky;
  //   top: 93px;
  //   background-color: #fff;
  //   z-index: 11;
  // }
}

.settings {
  background-color: white;
  .header.empl {
    padding: 0;
  }
  .settings__but-back {
    margin-bottom: 20px;
  }
  .scroll-menu-hor {
    .simplebar-track.simplebar-horizontal {
      display: none;
    }
  }
  .simplebar-content-wrapper {
    border-bottom: 1px solid #dee2e6;
    .nav-tabs {
      border-bottom: none;
      .nav-item {
        margin-bottom: 0px;
      }
      .nav-link {
        max-height: 38px;
      }
    }
  }
  .second-menu {
    flex-wrap: initial;
    &.right {
      margin-right: 50px;
    }
    .nav-item {
      white-space: nowrap;
    }
  }
  .header {
    display: flex;
    & > * {
      display: block;
      padding: 30px;
      width: fit-content;
    }
    .text {
      max-width: 50%;
      margin-top: 14px;
    }
  }
}
@media (max-width: 800px) {
  .header {
    .text {
      padding-bottom: 25px;
      padding-top: 26px;
    }
  }
}
@media (max-width: 800px) {
  .page {
    padding-top: 0px !important;
  }
}

.invisible_el {
  width: 0;
}

.second-menu {
  width: fit-content;
  .nav-scroll.right {
    position: sticky;
    right: 0;
    z-index: 1;
    background: linear-gradient(
      270deg,
      white -22.94%,
      rgba(255, 255, 255, 0) 330.33%
    );

    .nav-link {
      padding-left: 0;
      padding-right: 10px;
      max-height: 32px;
    }
  }
  .nav-scroll.left {
    position: sticky;
    left: 0;
    z-index: 1;
    @media (max-width: 800px) {
      left: 0;
    }
    background: linear-gradient(
      90deg,
      white -22.94%,
      rgba(255, 255, 255, 0) 330.33%
    );

    .nav-link {
      padding-left: 10px;
      padding-right: 0px;
      max-height: 32px;
    }
  }
}
</style>