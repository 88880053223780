<template>
  <div>
    <p>
      Поддерживаемые форматы файлов: mp3, wma или wav.
      <span class="file-subtext">Максимальный размер файла — 10 МБ</span>
    </p>
    <b-form-group>
      <input
        hidden
        type="file"
        ref="file_input"
        @change="set_file()"
        accept=".mp3, .wav, .wma"
      />
      <b-button
        variant="outline-primary file-btn"
        class="file-media-btn btn-select"
        @click="$refs.file_input.click()"
        >Выбрать файл</b-button
      >
      <span class="file-name">{{ file_name }}</span>
    </b-form-group>

    <div v-show="val">
      <div class="media-blocks">
        <b-form-group label="Название" label-class="media-block" label-cols="4">
          <b-form-input v-model="description" required />
        </b-form-group>
      </div>

      <audio controls id="audio_player" ref="player" />
      <!-- <b-form-group label="Тип">
        <b-form-select />

      </b-form-group> -->
      <b-button
        variant="outline-obitless"
        class="border-0 file-media-btn"
        @click="play_or_pause()"
      >
        <obit-icon icon="play" /> {{ play_button_text }}</b-button
      >
    </div>
  </div>
</template>

<script>
import { InputMixin } from "../../mixins";
import ObitIcon from "../../obit/ObitIcon.vue";

export default {
  name: "fileUpload",
  components: { ObitIcon },
  mixins: [InputMixin],
  data() {
    return {
      file_name: "",
      is_playing: false,
      description: "",
    };
  },

  mounted() {
    this.$watch("description", (to) => {
      this.parent.update_obj("description", to);
      this.parent.$forceUpdate();
    });
  },
  computed: {
    play_button_text() {
      return this.is_playing ? "Остановить" : "Прослушать";
    },
  },
  methods: {
    set_file() {
      const files = this.$refs.file_input.files;
      if (!this.multiple) {
        this.val = files;
				this.file_name = files[0].name;
				this.$refs.player.src = URL.createObjectURL(
						this.$refs.file_input.files[0]
				);
      }
    },
    play_or_pause() {
      if (this.is_playing) {
        this.$refs.player.pause();
        this.$refs.player.currentTime = 0;
        this.is_playing = false;
      } else {
        this.$refs.player.play();
        this.is_playing = true;
      }
    },
  },
};
</script>

<style lang="scss">
#audio_player {
  display: none;
}
.file-subtext {
  display: block;
}
.media-block {
  padding: 22px;
  align-items: center;
  justify-content: space-between;
  > .bv-no-focus-ring {
    width: 50%;
  }
}
.media-cell {
  word-break: break-all;
}
.file-media-btn {
  padding-left: 0;
}
.file-btn {
  padding: 10px 30px;
}
.btn-select {
  font-size: 11px;
  font-weight: bold;
  text-transform: uppercase;
}
.file-name {
  padding-left: 10px;
}
</style>