<template>
  <arkhive-list
    obj_type="call"
    :show_header="false"
    :keyword="type"
    :action="action"
    :filter_func="filter_func"
    ref="list"
    @update_count="count = $event"
  >
    <b-overlay :show="loading_media" />
    <b-form inline :class="[type]" @submit.prevent="func_fetch()">
      <b-col class="d-flex" v-if="show_direction_filter">
        <b-form-group label=" ">
          <b-radio-group
            v-model="call_type"
            :options="call_types"
            @input="func_fetch"
            buttons
            button-variant="outline-primary"
            class="btn-filter-height"
            size="sm"
          />
        </b-form-group>
      </b-col>
      <b-col class="d-flex search">
        <b-input-group
          label=" "
          class="search-input-group"
          style="margin-top: 7px"
        >
          <b-form-input
            @focus="focus = true"
            @blur="focus = false"
            :class="[
              'search-input',
              'btn-filter-height',
              { 'border-right-0': fast_filter, 'focus-input': fast_filter },
            ]"
            v-model="fast_filter"
            placeholder="Имя, номер или компания клиента"
          />
          <template v-slot:append>
            <b-button
              variant="outline"
              :class="[
                'border-left-0',
                'border',
                'text-primary',
                'clear-input',
                { focus: focus },
              ]"
              v-if="fast_filter"
              @click="
                fast_filter = '';
                func_fetch();
              "
              ><obit-icon icon="cancel"
            /></b-button>
          </template>
        </b-input-group>
      </b-col>

      <b-col class="d-flex additional-filter">
        <b-form-group label=" " class="">
          <b-button
            variant="btn btn-outline-primary"
            size="sm"
            class="btn-filter btn-filter-height btn-right btn-filter-text"
            @click="toggle_additional_filter()"
          >
            <obit-icon icon="filter" />
            ФИЛЬТРЫ
          </b-button>
          <b-button
            v-if="has_additional_filter"
            variant="btn btn-outline-primary"
            class="btn-filter btn-filter-height btn-filter-reset"
            @click="resetFilterHandler"
          >
            <obit-icon icon="close" />
          </b-button>
        </b-form-group>
      </b-col>
      <b-col
        flex
        v-if="show_export_button"
        class="d-flex flex-end margin-left-auto"
      >
        <b-form-group label=" ">
          <b-button
            variant="outline-export"
            class="btn-filter-height btn-right btn-filter-text"
            @click="export_file = true"
          >
            <obit-icon icon="xls" />
            ЭКСПОРТ
          </b-button>
        </b-form-group>
      </b-col>

    </b-form>
    <b-modal
      v-model="show_additional_filter"
      title="Фильтрация звонков"
      ok-title="Применить фильтр"
      no-close-on-backdrop
      @ok="modalFilterApplyHandler"
    >
      <obj-form
        v-model="filter_unsaved"
        :description="filter_description"
        raw
        mode="edit"
      >
        <b-form-group
          label="Период"
          label-cols="4"
          label-cols-sm="3"
          class="modal-filter"
        >
          <period-input v-model="filter_unsaved" />
        </b-form-group>
      </obj-form>
    </b-modal>
    <b-modal
      v-model="export_file"
      title="Экспорт файла"
      ok-title="Экспорт"
      no-close-on-backdrop
      @ok="export_data()"
    >
      <b-form-radio-group
        v-model="export_mode"
        :options="export_options"
        stacked
      ></b-form-radio-group>
    </b-modal>
  </arkhive-list>
</template>

<script>
import ObitIcon from "../obit/ObitIcon.vue";
import ArkhiveList from "../objects/ArkhiveList.vue";
import { buildRelatedField } from "../objects/fields";
import ObjForm from "../ObjForm.vue";
import CallsTypeField from "./CallsTypeField";
import { download_responce_ab } from "../../functions";
import { isEmpty, cloneDeep, omit } from "lodash-es";
import periodInput from "./periodInput.vue";

const options_incoming = [
  { text: "Принятые", value: "ANSWER" },
  { text: "Пропущенные", value: "CANCEL" },
];
const options_outgoing = [
  { text: "Отвеченные", value: "ANSWER" },
  { text: "Без ответа", value: "NOANSWER" },
  { text: "Занято", value: "BUSY" },
];
const row_props = {
  "label-cols": 4,
  "label-cols-sm": 3,
};

const filter_descr = {
  verbose: "Фильтр",
  fields: {
    incoming: {
      label: "Результаты звонков",
      comp: CallsTypeField,
      row_props,
      options: { options_list: options_incoming, label_text: "Входящие" },
    },
    outgoing: {
      label: "  ",
      comp: CallsTypeField,
      row_props,
      options: { options_list: options_outgoing, label_text: "Исходящие" },
    },
    external_nums: buildRelatedField("Внеш. номера", "city_number", {
      multiple: true,
      row_props,
      options: {
        list_key: "obj_name",
        required: false,
        add_select: true,
        no_clear: true,
      },
    }),
    internal_nums: buildRelatedField("Внутр. номера", "internal_line2", {
      multiple: true,
      row_props,
      options: {
        list_key: "number",
        list_text: "number",
        required: false,
        add_select: true,
        no_clear: true,
      },
    }),
    // date_range: {
    //   label: "Время",
    //   comp: BFormRadioGroup,
    //   row_props,
    //   options: {
    //     options: [
    //       { text: "Сегодня", value: 1 },
    //       { text: "Неделя", value: 2 },
    //       { text: "Месяц", value: 3 },
    //       { text: "Произвольный диапазон", value: 4 },
    //     ],
    //   },
    // },
    // begin: {
    //   label: "Дата начала выборки",
    //   comp: DatePicker,
    //   row_props,
    //   rule: (item) => item.date_range == "4",
    // },
    // end: {
    //   label: "Дата конца выборки",
    //   comp: DatePicker,
    //   row_props,
    //   rule: (item) => item.date_range == "4",
    // },
    // pbx_group: buildRelatedField("Группы", "pbx_group", { multiple: true }),
    // operator: buildRelatedField("Клиенты", "employee"),
  },
};

export default {
  name: "CallHistory",
  components: { ArkhiveList, ObitIcon, ObjForm, periodInput },
  provide() {
    return {
      set_media_loading: (val) => (this.loading_media = val),
    };
  },
  props: {
    type: {
      type: String,
      required: true,
      validator: (val) => ["internal", "external", "line"].indexOf(val) !== -1,
    },
    exten: {
      type: String,
      required: false,
      default: null,
    },
    only_status: {
      type: Object,
      required: false,
      default: null,
    },
  },
  mounted() {
    this.$watch(
      () =>
        JSON.stringify([
          this.$store.getters.current("entity", "instance"),
          this.$props,
        ]),
      () => {
        this.filter_additional = this.build_default_additional_filter();
        this.filter_unsaved = this.build_default_additional_filter();
        this.func_fetch();
      }
    );
    this.$watch(
      () => {
        const incoming = this.filter_additional.incoming;
        const outgoing = this.filter_additional.outgoing;
        return JSON.stringify([incoming?.length, outgoing?.length]);
      },
      (n) => {
				if (this.call_type === `all`) {
					let callType = "all";
					const [incoming, outgoing] = JSON.parse(n);
					if (typeof incoming === `number`) {
						callType = "incoming";
					} else if (typeof outgoing === `number`) {
						callType = "outgoing";
					}

					this.call_type = callType;
				}
      }
    );

    this.$watch(
        () => {
          const incoming = this.filter_unsaved.incoming;
          const outgoing = this.filter_unsaved.outgoing;
          return JSON.stringify([incoming, outgoing]);
        },
        (n) => {
          const [incoming, outgoing] = JSON.parse(n)

          if (this.call_type === `all`) {
            if (incoming?.length === 0) {
              delete this.filter_unsaved.incoming
            }

            if (outgoing?.length === 0) {
              delete this.filter_unsaved.outgoing
            }
          }
        }
    );
  },
  data() {
    return {
      count: 0,
      filter_direction: null,
      fast_filter: "",
      show_additional_filter: false,
      loading_media: false,
      filter_additional: this.build_default_additional_filter(),
      filter_unsaved: this.build_default_additional_filter(),
      export_file: false,
      export_mode: "",
      focus: false,
      reset_filter: false,
      call_type: "all",
			has_additional_filter: false
    };
  },
  watch: {
    call_type(n, o) {
      if (n != o) {
        switch (n) {
          case "incoming":
            if (this.filter_additional.outgoing) {
              this.filter_additional = omit(this.filter_additional, [
                "outgoing",
              ]);
            }

            if (this.filter_unsaved.outgoing) {
              this.filter_unsaved = omit(this.filter_unsaved, [
                "outgoing",
              ]);
            }

						if (!this.filter_additional.incoming) {
							this.filter_additional.incoming = []
						}

            if (!this.filter_unsaved.incoming) {
              this.filter_unsaved.incoming = []
            }

            break

          case "outgoing":
            if (this.filter_additional.incoming) {
              this.filter_additional = omit(this.filter_additional, [
                "incoming",
              ]);
            }

            if (this.filter_unsaved.incoming) {
              this.filter_unsaved = omit(this.filter_unsaved, [
                "incoming",
              ]);
            }

						if (!this.filter_additional.outgoing) {
							this.filter_additional.outgoing = []
						}

            if (!this.filter_unsaved.outgoing) {
              this.filter_unsaved.outgoing = []
            }

            break

          case "all":
            // eslint-disable-next-line no-case-declarations
            const fields_to_omit = []
            // eslint-disable-next-line no-case-declarations
            const unsaved_fields_to_omit = []

            if (this.filter_additional.outgoing) {
              fields_to_omit.push("outgoing");
              // delete this.filter_additional.outgoing;
            }

            if (this.filter_additional.incoming) {
              fields_to_omit.push("incoming");
              // delete this.filter_additional.incoming;
            }

            if (this.filter_unsaved.outgoing) {
              unsaved_fields_to_omit.push("outgoing");
            }

            if (this.filter_unsaved.incoming) {
              unsaved_fields_to_omit.push("incoming");
            }

            if (fields_to_omit.length) {
              this.filter_additional = omit(
                this.filter_additional,
                fields_to_omit
              )
            }

            if (unsaved_fields_to_omit.length) {
              this.filter_unsaved = omit(
                  this.filter_unsaved,
                  unsaved_fields_to_omit
              );
            }

            break
        }
      }
    },

    type() {
      this.filter_direction = null;
      this.call_type = "all";
      this.fast_filter = "";
    },
  },
  computed: {
    export_options: () => [
      { text: "Excel", value: "xlsx" },
      { text: "JSON", value: "json" },
      { text: "CSV", value: "csv" },
    ],
    filter_description() {
      let ret = cloneDeep(filter_descr);
      if (this.only_status) {
        delete ret.fields.incoming;
        delete ret.fields.outgoing;
      } else {
        if (this.call_type == "incoming" || this.filter_unsaved?.incoming?.length) {
          delete ret.fields.outgoing;
        } else if (this.call_type == "outgoing" || this.filter_unsaved?.outgoing?.length) {
          ret.fields.outgoing.label = ret.fields.incoming.label;
          delete ret.fields.incoming;
        }
      }
      return ret;
    },
    show_direction_filter() {
      let ret = ["internal", "line"].indexOf(this.type) == -1;
      if (this.only_status) {
        ret = false;
      }
      return ret;
    },
    show_export_button() {
      let ret = ["internal", "line"].indexOf(this.type) == -1;
      return ret && !!this.count;
    },
    action() {
      let ret = null;
      if (this.type == "line") {
        ret = "call/get_by_ext";
      }
      return ret;
    },
    export_dis() {
      return !isEmpty(this.filter_additional) || !isEmpty(this.fast_filter);
    },
    call_types: () => [
      { value: "all", text: "Все" },
      { value: "incoming", text: "Входящие" },
      { value: "outgoing", text: "Исходящие" },
    ],
  },
  methods: {
    build_default_additional_filter: () => ({
      date_start: null,
      date_end: null,
    }),
    func_fetch(force) {
      if (this.show_additional_filter && !force) {
        return;
      }
      if (this.reset_filter == true) {
        this.reset_filter = false;
      } else {
        this.reset_filter = true;
      }
      this.$refs.list.fetch();
    },
    filter_func() {
      let filter = {};
      let ret = { filter };

      let add_filter = cloneDeep(this.filter_additional);
      if (add_filter) {
        let { date_start, date_end } = add_filter;
        [ret.begin, ret.end] = [
          date_start ? date_start + " 00:00:00" : null,
          date_end ? date_end + " 23:59:59" : null,
        ];
        delete add_filter.date_start;
        delete add_filter.date_end;
        // if (this.filter_additional["external_nums"]) {
        //   this.filter_additional["external_nums"] = [
        //     this.filter_additional["external_nums"],
        //   ];
        // }
        ret["filter"] = omit(add_filter, ["incoming", "outcoming"]);

        if (this.export_file && this.export_mode) {
          ret["mode"] = this.export_mode;
          ret["name"] = `callhistory.${this.export_mode}`;
          ret["from"] = 0;
        }
      }
      if (this.fast_filter) {
        let fast_filter = this.fast_filter;
        // clear fast_filter
        fast_filter = fast_filter.replace(/[^\d]+/g, "");
        if (fast_filter.match(/^8\d{10}$/)) {
          fast_filter = fast_filter.slice(1);
        }
        filter["fast_search"] = fast_filter;
        // this.fast_filter = fast_filter;
        ret["filter"] = filter;
      }
      if (["internal", "external"].indexOf(this.type) !== -1) {
        // фильтр для внутренних линий
        ret["local"] = this.type == "internal";

        if (this.filter_direction) {
          filter[this.filter_direction] = [];
        }
      } else if (this.type == "line") {
        ret["ext"] = parseInt(this.exten);
        if (this.filter_direction) {
          let filter = [
            {
              field: this.filter_direction == "incoming" ? "num_b" : "num_a",
              comparison: "eq",
              value: this.exten,
            },
          ];
          ret["filter"] = filter;
        }
      }

      switch (this.call_type) {
        case "outgoing":
          if (add_filter.outgoing?.length) {
            ret.filter.outgoing = add_filter.outgoing;
          } else {
            ret.filter.outgoing = [];
          }
          break;
        case "incoming":
          if (add_filter.incoming?.length) {
            ret.filter.incoming = add_filter.incoming;
          } else {
            ret.filter.incoming = [];
          }
          break;
        case "all":
          if (add_filter?.incoming?.length) {
            ret.filter.incoming = add_filter.incoming;
          } else {
            delete ret.filter.incoming;
          }
          if (add_filter?.outgoing?.length) {
            ret.filter.outgoing = add_filter.outgoing;
          } else {
            delete ret.filter.outgoing;
          }
          break;
      }

      // отнормализируем внешние номера
      if (ret.filter.external_nums) {
        let additional_nums = [];
        for (const ind in ret.filter.external_nums) {
          let num = ret.filter.external_nums[ind];
          if (/^\d{10}$/.test(num)) {
            additional_nums.push(num.slice(3));
          }
          if (/^[78]\d{10}$/.test(num)) {
            additional_nums.push(num);
            additional_nums.push(num.slice(4));
            num = num.slice(1);
          }

          ret.filter.external_nums[ind] = num;
        }
        ret.filter.external_nums =
          ret.filter.external_nums.concat(additional_nums);
      }

			if (this.only_status) {
				delete ret.filter.outgoing;
				delete ret.filter.incoming;
				Object.entries(this.only_status).map(
						([key, val]) => (ret.filter[key] = val)
				);
			} else {
				if (this.call_type !== `incoming`) {
					if (ret?.filter["incoming"] && ret.filter["incoming"].length == 0) {
						delete ret.filter["incoming"];
					}
				}

				if (this.call_type !== `outgoing`) {
					if (ret?.filter["outgoing"] && ret.filter["outgoing"].length == 0) {
						delete ret.filter["outgoing"];
					}
				}
			}

			//Cancel filter button visibility conditions
			if (
          this.call_type !== `all`
					|| this.filter_additional.internal_nums
					|| this.filter_additional.external_nums
					|| this.filter_additional.incoming?.length > 0
					|| this.filter_additional.outgoing?.length > 0
					|| this.filter_additional.date_end
					|| this.filter_additional.date_start
			) {
				this.has_additional_filter = true
			} else this.has_additional_filter = false

      return ret;
    },
    toggle_additional_filter() {
      this.show_additional_filter = true;
    },
    export_data() {
      let url = "pbx/reports/callhistory";
      let data = {
        customer_id: this.$store.getters["current_customer"],
        instance_id: this.$store.getters["current_instance"],
        order: "date",
        count: this.$refs.list.count,
        from: 0,
        direction: "desc",
        ...this.filter_func(),
      };
      if (this.type == "line") {
        url = "/pbx/reports/exthistory";
      }
      this.$store
        .dispatch(
          "requestApi",
          {
            url: url,
            data: data,
            additional_config: {
              responseType: "arraybuffer",
            },
          },
          { root: true }
        )
        .then((resp) => {
          download_responce_ab(resp, `callhistory.${this.export_mode}`);
        });
    },

    modalFilterApplyHandler() {
      let isFetch = false

      // Fetch list only if not changed call_type
      if (this.filter_additional.outgoing && this.filter_unsaved.outgoing) isFetch = true
      else if (this.filter_additional.incoming && this.filter_unsaved.incoming) isFetch = true
      else if (
          (!this.filter_additional.outgoing && !this.filter_unsaved.outgoing)
          && (!this.filter_additional.incoming && !this.filter_unsaved.incoming)) isFetch = true

      this.filter_additional = cloneDeep(this.filter_unsaved)

      if (isFetch) this.func_fetch(true)
    },

    resetFilterHandler() {
      this.filter_additional = this.build_default_additional_filter()
      this.filter_unsaved = this.build_default_additional_filter()

      if (this.call_type !== `all`) {
        this.call_type = `all`
      } else {
        this.func_fetch()
      }
    }
  },
};
</script>

<style lang='scss'>
/* .archive .form-inline.internal {
  justify-content: space-between !important;
} */

.modal-filter {
  .d-flex.col {
    padding-left: 0;
    &.additional-filter {
      padding-left: 0;
      @media screen and (min-width: 450px) {
        .form-group {
          & > [role="group"] {
            display: flex;
            flex-wrap: nowrap;
            flex-direction: row;

            .obit-datepicker {
              &:not(:first-child) {
                margin-left: 1em;
              }
              .legend {
                margin-right: 1em;
              }
            }
          }
        }
      }
      @media screen and (max-width: 449px) {
        .form-group {
          & > [role="group"] {
            .obit-datepicker {
              margin-bottom: 0.5em;
              .legend {
                min-width: 2em;
              }
            }
          }
        }
      }
    }
  }
}

.archive.call {
  .btn-height {
    height: 37px;
  }
  .form-inline.external {
    justify-content: initial;
  }
  .additional-filter {
    display: inline-flex;
    div.bv-no-focus-ring {
      display: inline-flex;
    }
    & .btn {
      margin-right: 0;
    }

		.form-group > div {
			display: flex;
			align-items: center;
		}
  }
  .form-inline {
    .col.search {
      flex-grow: 1;
      max-width: 100%;
      .search-input-group {
        width: 100%;
        max-width: 210px;
      }
    }
    &.external {
      justify-content: initial;
    }
    &.line {
      justify-content: initial;
    }
  }
  .btn-filter-height {
    height: 37px;
    border-radius: 6px 6px 6px 6px;
    &.btn-group-toggle {
      label {
        padding-left: 15px;
        padding-right: 15px;
        color: #3e3e3e;
      }
    }
    &.search-input.focus-input {
      border-radius: 6px 0px 0px 6px;
    }
    &.search-input::placeholder {
      color: #3e3e3e;
    }
  }
  .focus {
    border-color: var(--primary) !important;
  }
  // .focus-input {
  //   max-width: 157px;
  // }
  .clear-input {
    padding-bottom: 4px;
  }
  .btn-right {
    width: 115px;
    white-space: nowrap;
  }
  .btn-filter-reset {
    border: none;
  }
  .btn-filter-text {
    font-size: 11px;
  }
  .search-input-group {
    min-width: 150px;
    @media (min-width: 1000px) {
      min-width: 286px;
    }
  }
}
</style>