<template>
  <b-button-group size="sm">
    <b-button
      variant="outline-primary"
      class="border-0"
      v-b-tooltip="'Редактировать'"
      @click="$refs.edit.$emit('show')"
      v-if="deleteButton('edit')"
      :disabled="disabledButton('edit')"
    >
      <obit-icon icon="lkEdit" class="lkEdit-btn" />
    </b-button>
    <b-button
      variant="outline-primary"
      class="border-0 media-btn-settings"
      v-b-tooltip="'Скачать'"
      v-if="deleteButton('download')"
      :disabled="disabledButton('download')"
      @click="download()"
    >
      <obit-icon icon="lkDownload" />
    </b-button>
    <b-button
      variant="outline-primary"
      class="border-0"
      v-b-tooltip="'Удалить'"
      @click="remove_item()"
      v-if="deleteButton('remove')"
      :disabled="disabledButton('remove')"
    >
      <obit-icon icon="trash" class="hover-danger" />
    </b-button>
<!--    <b-modal v-model="show_player" title="проиграть файл" lazy>-->
<!--      {{ audio_src }}-->
<!--      <audio :src="audio_src" />-->
<!--    </b-modal>-->
    <obj-modal
      :obj_type="item.obj_type"
      :obj_id="item.obj_id"
      modalOnly
      mode="edit"
      ref="edit"
    />
  </b-button-group>
</template>

<script>
import {
  faDownload,
  faEdit,
  faPlayCircle,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import Icon, { as_icon } from "../../layot/FontAwesomeIcon.vue";
import CellVue from "../../mixins/Cell.vue";
import ObjModal from "../ObjModal.vue";
import { ObitIcon } from "../../obit";
import { download_from_responce } from "@/functions.js";
export default {
  name: "actionsCell",
  mixins: [CellVue],
  components: { Icon, ObjModal, ObitIcon },
  data: () => ({
    show_player: false,
  }),
  computed: {
    icons: () =>
      Object.assign({
        edit: as_icon(faEdit),
        download: as_icon(faDownload),
        delete: as_icon(faTrash),
        play: as_icon(faPlayCircle),
      }),
    audio_src() {
      // const token = this.$store.getters.access_token();
      // const url = "stage-instance-behemoth-test.lobby.obit.ru";
      // const media_file_id = this.item.obj_id;
      // return `https://${url}/media/play?media_id=${media_file_id}&token=${token}`;
      return ``
    },
  },
  methods: {
    download() {
      this.$store
        .dispatch("media/get_media", this.item.obj_id)
        .then((resp) =>
          download_from_responce(resp, `${this.item.obj_id}.wav`)
        );
    },
    remove_item() {
      if (confirm(`Точно удалить файл ${this.item.obj_name}?`)) {
        this.$store.dispatch(`${this.item.obj_type}/delete`, this.item.id);
      }
    },
    deleteButton(type) {
      if (
        this.field.options &&
        this.field.options.delete &&
        this.field.options.delete.includes(type)
      ) {
        return false;
      }
      return true;
    },
    disabledButton(type) {
      if (
        this.field.options &&
        this.field.options.disabled &&
        this.field.options.disabled.includes(type)
      ) {
        return true;
      }
      return false;
    },
  },
};
</script>

<style lang='scss'>
.btn-outline-primary:hover .hover-danger {
  &:hover {
    color: red !important;
    border-color: red !important;
  }
}
.lkEdit-btn {
  width: 1.9em !important;
  height: 1.2em !important;
}
.objects-media,
.objects-play_file {
  td.field_actions,
  th.field_actions {
    width: 40px;
  }
  .media-btn-settings {
    padding: 0 40px;
  }
}
</style>