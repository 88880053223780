<template>
  <div>
    <smart-button v-bind="btn_props" @click="onSmartBtnClickHandler" />
    <b-modal
      title="Добавление номера"
      v-model="show_modal"
      no-close-on-backdrop
      hide-footer
    >
      <!-- <p>Вы можете купить номер на ваш вкус</p> -->
      <p>
        Городской номер для входящей / исходящей связи
        <obit-icon icon="question" />
      </p>
      <!-- <b-radio-group v-model="number_type" stacked>
        <b-radio value="city"
          >Городской номер для входящей / исходящей связи
          <obit-icon icon="question" />
        </b-radio>
        <b-radio value="virtual" disabled
          >Виртуальный номер для входящей связи
          <obit-icon icon="question" />
        </b-radio>
        <b-radio value="federal" disabled
          >Федеральный номер 8 800 / 8 804
          <obit-icon icon="question" />
        </b-radio>
      </b-radio-group> -->
      <b-button variant="obit" @click="goModal"> купить новый номер </b-button>
      <manager-modal ref="man_modal" />
      <div class="own-number">
        <p>У вас есть номер, с которым вы не хотите расставаться?</p>
        <b-button variant="obit" @click="show_ext_modal = true"
          >подключить свой номер
        </b-button>
      </div>
    </b-modal>
    <b-modal
      title="Подключение существующего номера"
      v-model="show_ext_modal"
      no-close-on-backdrop
      ok-title="Подключить"
      cancel-title="Отмена"
      cancel-variant="outline-obit"
      :ok-disabled="!is_form_ready"
      @ok="alien"
    >
      <b-form>
        <b-form-group label-cols="3" label="Название">
          <b-input v-model="ext_data.caption" required />
        </b-form-group>
        <b-form-group label-cols="3" label="Номер телефона">
          <b-input v-model="ext_data.number" required />
        </b-form-group>
        <b-form-group label-cols="3" label="Имя сервера">
          <b-input v-model="ext_data.sipServer" required />
        </b-form-group>
        <b-form-group label-cols="3" label="SIP-логин">
          <b-input v-model="ext_data.sipLogin" required />
        </b-form-group>
        <b-form-group label-cols="3" label="SIP-пароль">
          <b-input v-model="ext_data.sipPassword" type="password" required />
        </b-form-group>
        <b-form-group label-cols="3" label="Комментарий">
          <b-textarea v-model="ext_data.description" />
        </b-form-group>
      </b-form>

      <p v-if="!isOnPrem">
        Подключение данного номера к вашей ВАТС составляет 330 руб./мес<span
          v-if="is_form_ready"
      >. Продолжить?</span
      >
      </p>
    </b-modal>
    <b-modal v-model="show_confirm" ok-only ok-title="продолжить" @ok="confirm">
      <h2>
        {{ confirm_msg }}
      </h2>
    </b-modal>
  </div>
</template>

<script>
import { merge, pick } from "lodash-es";
import SmartButton from "../../layot/SmartButton.vue";
import { ObitIcon } from "../../obit";
import ManagerModal from "../../obit/ManagerModal";
const new_alient_msg =
  "Заказ на подключение номера передан в работу. " +
  "Ваш номер уже доступен для настройки маршрутизации. " +
  "После завершения работ по подключению номера к ВАТС, звонки будут поступать вашим сотрудникам.";

const new_city_msg =
  "Запрос на подключение нового номера к BATC получен. Менеджер свяжется с вами для подбора номера и обсуждения сроков подключения к BATC";

const req_fields = [
	"number",
	"sipServer",
	"sipLogin",
	"sipPassword",
	"caption",
];

const add_fields = ["description"];

export default {
  name: "CityNumberCreateButton",
  components: { SmartButton, ObitIcon, ManagerModal },
  data() {
    const ext_data = this.buildDefaultData()

    return {
      show_modal: false,
      show_ext_modal: false,
      number_type: "city",
      req_fields,
      ext_data: ext_data,
      show_confirm: false,
    };
  },
  computed: {
    btn_props: () => ({
      osnPart: process.env.VUE_APP_EXTERNAL_NUMBERS !== `false` ? `Заказать` : `Подключить`,
      osnIcon: ObitIcon,
      osnIconProps: { icon: "plus" },
      secondPart: " номер",
      buttonProps: {
        variant: "outline-obitless",
      },
    }),
    is_form_ready() {
      return Object.values(pick(this.ext_data, this.req_fields)).every(
        (p) => p != ""
      );
    },
    confirm_msg() {
      return this.number_type === `alien` ? new_alient_msg : new_city_msg;
    },

    isOnPrem() {
      return process.env.VUE_APP_EXTERNAL_NUMBERS === `false`
    }
  },
  methods: {
    confirm() {
      this.show_ext_modal = false;
      this.show_modal = false;
			this.ext_data = this.buildDefaultData()
    },

    onSmartBtnClickHandler() {
      if (!this.isOnPrem) this.show_modal = true
      else this.show_ext_modal = true
    },

    goModal() {
      this.$refs["man_modal"].show("newnumber");
    },

    alien(event) {
      event.preventDefault();
      this.number_type = `alien`
      const action = !this.isOnPrem ? `city_number/alien` : `city_number/termination`
      const formData = this.ext_data

      if (this.isOnPrem) {
        formData.user = formData.sipLogin
        formData.password = formData.sipPassword
        formData.server = formData.sipServer
        formData.proto = `udp`
        formData.behindNat = true

        delete formData.sipLogin
        delete formData.sipPassword
        delete formData.sipServer
      }

      this.$store.dispatch(action, formData).then((r) => {
        if (r?.data?.result === "success") {
          this.$store.dispatch("city_number/list");
          this.show_confirm = true;
        } else {
          let message = r.response?.data?.text ?? "Ошибка при отправке заявки";
          this.$store.commit("add_message", {
            type: "danger",
            message,
          });
          this.confirm();
        }
      });
    },

		buildDefaultData() {
			return [...req_fields, ...add_fields].reduce(
					(r, f) => merge(r, { [f]: "" }),
					{}
			);
		}
  },
};
</script>

<style lang='scss'>
.own-number {
  background-color: var(--light);
  margin-left: -30px;
  margin-right: -30px;
  margin-bottom: -15px;
  margin-top: 15px;
  padding: 30px;
}
</style>