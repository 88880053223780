import { id_field_desc } from "@/functions.js"
import { buildRelatedField } from "../fields"
import MediaNamecell from './MediaNameCell'
import actionsCell from './actionsCell'
import fileUpload from "./fileUpload";
import { SmartButton } from '../../layot'
import ObitIcon from "../../obit/ObitIcon"
import { shortText } from "../../shared";
import NumberInput from "./NumberInput"
import { InputMixin } from "../../mixins/index";
const URLS = {
  list: '/media/files',
  add: '/media/upload',
  delete: '/media/remove'
}
const root = '/pbx/services/v2/playFile'

const PF_URLS = {
  list: `${root}/list`,
  update: `${root}/modify`,
  add: `${root}/create`
}
const DISA_URL = {
  list: `/pbx/services/v2/disa/list`,
  add: `/pbx/services/v2/disa/create`,
  update: `/pbx/services/v2/disa/modify`,
  delete: `/pbx/services/v2/disa/delete`
}
const HANGUP_URLS = {
  list: '/pbx/services/v2/hangUp/list',
  add: '/pbx/services/v2/hangUp/create',
  update: '/pbx/services/v2/hangUp/modify'
}

const MOH_URLS = {
  list: '/pbx/services/v2/moh/publicList',
}

const media_status = {
  'public': 'Общий',
  'private': 'Личный'
}

const storage = {
  getters: {
    post_data_delete: () => data => ({
      id: data[0]
    }),
    calced_options: () => obj => {
      let type = 'private'
      if (obj.isPublic == true) {
        type = 'public'
      }
      let ret = {
        media_type: media_status[type]
      }

      return ret
    }
  },
  actions: {
    get_media({ dispatch, rootGetters }, media_id) {
      return dispatch(
        'requestApi',
        {
          url: 'media/fileRequest',
          method: 'POST',
          data: {
            customer_id: rootGetters.current_customer,
            id: media_id
          }
        }, { root: true }
      ).then((response) => {
        return dispatch('requestApi', {
          url: `download?t=${response.data.payload.t}`,
          method: 'GET',
          additional_config: {
            responseType: 'arraybuffer'
          },
        }, { root: true })
      })
    },
  }
}

const media_description = {
  verbose: 'Медиа-файл',
  plural: 'Медиа-файлы',
  obj_name: 'title',
  urls: URLS,
  no_create_button: true,
  no_trash_button: true,
  create_button: SmartButton,
  create_title: 'создать медиафайл',
  create_button_props: {
    osnPart: 'Создать',
    osnIcon: ObitIcon,
    osnIconProps: { icon: 'plus' },
    secondPart: 'Медиафайл'
  },
  unique_for: ['customer', 'instance'],
  storage: storage,
  withFiles: true,
  obj_template: obj => obj.description || obj.title,
  fields: {
    id: id_field_desc,
    pbx_file_name: { label: 'Название', validators: { 'Длина должна быть от 3 до 30 символов': value => value.length > 3 && value.length <= 30 }, },
    title: { label: 'Имя файла' },
    player: { label: 'Название мелодии', cell: MediaNamecell },
    files: 'files',
    description: { label: 'Название мелодии', },
    status: 'Статус',
    tags: buildRelatedField(
      'Тэги', 'tag', { multiple: true }
    ),
  },
  list_fields: ['player', 'title', {
    label: '', key: 'actions', cell: actionsCell, options: {
      delete: ['edit']
    }
  }
  ],
  edit_fields: ['description', 'title'],
  new_fields: [
    // 'description',
    {
      target: 'files',
      label: " ",
      row_props: { "label-cols": 0 },
      comp: fileUpload,
      required: true,
      validator: (file) => {
        if (file) {
          const fileTypes = [
            // wma
            `audio/x-ms-wma`,
            `video/x-ms-asf`,
            `video/x-ms-wma`,
            // MIMEs wav
            `audio/vnd.wave`,
            `audio/x-wav`,
            `audio/wave`,
            `audio/wav`,
            // MP3
            `audio/mpeg`, //IE, Firefox
            `audio/mp3`, //Chrome, Opera
          ]

          for (let i = 0; i < fileTypes.length; i++) {
            if (file[0].type === fileTypes[i]) {
              return null
            }
          }

          return `Невалидный тип файла`
        }

      }
    },
  ],
  list_fields_search: ['description', 'title'],
}

const PF_DESC = {
  verbose: 'голосовое сообщение',
  update_text: 'Услуга Голосовое сообщение обновлена',
  urls: PF_URLS,
  obj_type: 'play_file',
  unique_for: ['customer', 'entity'],
  // hiddenMediaInfo: true,
  create_button: SmartButton,
  create_title: 'создать голосовое сообщение',
  create_button_props: {
    osnPart: 'Создать',
    osnIcon: ObitIcon,
    osnIconProps: { icon: 'plus' },
    secondPart: 'голосовое сообщение'
  },
  obj_template: (obj) => {
    let ret = obj.description
    if (!ret) {
      const media = obj._related('mediaFileId')
      if (media) {
        ret = media.obj_name
      }
    }
    return ret
  },
  fields: {
    service_id: id_field_desc,
    player: { label: 'Название', cell: MediaNamecell, target: 'mediaFileId' },
    // caption: { label: 'Название', isKey: true, isName: true },
    description: { label: 'Название', isKey: true, isName: true, cell: shortText },
    number: 'Номер',
    mediaFileId: buildRelatedField('Название медифайла', 'media', { cell: shortText }),
    repeatCount: { label: 'Повторы', comp: NumberInput },
  },
  list_fields: ['player', 'mediaFileId', 'number', { label: '', key: 'actions', cell: actionsCell, options: { delete: ['download', 'remove'] } }],
  view_fields: ['description', 'mediaFileId', 'repeatCount'],
  edit_fields: ['description', 'mediaFileId', 'repeatCount'],
  new_fields: ['description', 'mediaFileId', 'repeatCount'],
  list_fields_search: ['description', 'mediaFileId'],
}

const DISA_DESC = {
  verbose: 'Донабор номера',
  urls: DISA_URL,
  obj_type: 'disa',
  obj_name: 'caption',
  unique_for: ['customer', 'entity'],
  fields: {
    service_id: id_field_desc,
    caption: { label: 'Название', isKey: true, isName: true, required: true },
    description: { label: 'Комментарий' },
    number: { label: 'Номер' },
    numberLength: { label: 'Длина набора номера', comp: InputMixin, options: { convert_type: parseInt, type: 'number', min: "0" } },
    awaitTime: { label: 'Время ожидания, сек.', comp: InputMixin, options: { convert_type: parseInt, type: 'number', min: "0" } },
    sound: buildRelatedField('Медиафайл для сообщения', 'media'),
    mediaFileId: buildRelatedField('Медиафайл для сообщения', 'media')
  },
  list_fields: ['caption', 'description', 'sound', 'awaitTime', 'numberLength'],
  view_fields: ['caption', 'description', 'sound', 'awaitTime', 'numberLength'],
  new_fields: ['caption', 'description', 'mediaFileId', 'awaitTime', 'numberLength'],
  edit_fields: ['caption', 'description', 'sound', 'awaitTime', 'numberLength'],
}

const HANGUP_DESC = {
  verbose: 'Повесить трубку',
  urls: HANGUP_URLS,
  unique_for: ['customer', 'entity'],
  obj_type: 'hangup',
  fields: {
    service_id: id_field_desc,
    caption: { label: 'Название', isKey: true, isName: true },
    number: 'Номер'
  },
  list_fields: ['caption', 'number'],
  view_fields: ['caption'],
  new_fields: ['caption'],
  edit_fields: ['caption'],
}

const MOH_DESC = {
  verbose: 'MOH',
  urls: MOH_URLS,
  unique_for: ['customer', 'entity'],
  obj_type: 'moh',
  storage: storage,
  no_create_button: true,
  fields: {
    service_id: id_field_desc,
    caption: { label: 'Название', isKey: true, isName: true },
    description: 'Описание',
  },
  list_fields: ['caption', 'description', {
    label: 'Тип',
    key: 'media_type',
  }, { label: '', key: 'actions', cell: actionsCell, options: { delete: ['download', 'edit', 'remove']} }],
  view_fields: ['caption', 'description'],
  edit_fields: ['caption', 'description'],
}

const test_medias = []
export { media_description as MEDIA_DESC, test_medias, PF_DESC, DISA_DESC, HANGUP_DESC, MOH_DESC }