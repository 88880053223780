<template>
  <nobr class="record-input">
    <b-form-checkbox v-model="val" class="input" :disabled="real_disabled">
      Включить запись
      <obit-icon
        icon="question"
        v-b-tooltip="{ title: help_text, variant: 'light' }"
      />
    </b-form-checkbox>
  </nobr>
</template>

<script>
import { InputMixin } from "../../mixins";
import ObitIcon from "../../obit/ObitIcon.vue";

export default {
  components: { ObitIcon },
  name: "RecordInput",
  mixins: [InputMixin],
  mounted() {
    this.$watch(() => this._obj?.monitor, this.$forceUpdate);
    this.$store.dispatch("callRecords/list").then(this.$forceUpdate);
  },
  computed: {
    help_text() {
      return 'Управление доступно при подключении услуги "Запись и хранение звонков" в разделе "Тарифы". Прослушивание записанных звонков в Разделе "Звонки"';
    },
    call_records_length() {
      return this.$store.getters["callRecords/objects"].length;
    },
    real_disabled() {
      return !this.call_records_length || !!this.disabled;
    },
  },
};
</script>

<style lang='scss'>
.record-input {
  .input {
    display: inline;
  }
}
</style>